<script setup lang="ts">
const nuxtApp = useNuxtApp()
const { $i18n } = nuxtApp

useHead({
  titleTemplate: (titleChunk) => {
    return titleChunk ? `${titleChunk} - ${$i18n.t('siteTitle')}` : `${$i18n.t('siteTitle')}`
  },
  script: [
    {
      src: 'https://unpkg.com/large-small-dynamic-viewport-units-polyfill@0.0.4/dist/large-small-dynamic-viewport-units-polyfill.min.js'
    },
    {
      hid: "INITIATE",
      src: "/metapixel.js",
      defer: true,
      type: "text/javascript"
    },
  ],
  link: [
    { rel: 'apple-touch-icon', sizes: '180x180', href: '/apple-touch-icon.png' },
    { rel: 'icon', type: 'image/png', sizes: '32x32', href: '/favicon-32x32.png' },
    { rel: 'icon', type: 'image/png', sizes: '16x16', href: '/favicon-16x16.png' },
    { rel: 'manifest', href: '/site.webmanifest' },
  ]
})

useSeoMeta({
  viewport: 'user-scalable=no, initial-scale=1, maximum-scale=1, minimum-scale=1, width=device-width, height=device-height, target-densitydpi=device-dpi'
})
</script>

<template>
  <div id="app">
    <NuxtLayout>
      <ClientOnly>
        <NavigationLoadingIndicatorComponent />
      </ClientOnly>
      <NuxtPage />
    </NuxtLayout>
    <img height="1" width="1" style="display:none" src="https://www.facebook.com/tr?id=514142324269269&ev=PageView&noscript=1">
  </div>
</template>
